import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import PaperDetails from './components/paper-details/PaperDetails';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Navigation from './components/Navigation';
import Terms from './components/Terms';
import RedirectHandler from './components/RedirectHandler';
import { AuthProvider, useAuth } from './context/AuthContext';
import BattleVisualizations from './components/BattleVisualizations';
import About from './components/About';

const AuthStatusWrapper = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return React.cloneElement(children, { isAuthenticated: isAuthenticated() });
};

const App = () => {

  useEffect(() => {
    const buildTime = process.env.REACT_APP_BUILD_TIME;
    const lastBuildTime = localStorage.getItem('build_time');
    
    if (lastBuildTime !== buildTime) {
      localStorage.setItem('build_time', buildTime);
      window.location.reload(true);
    }
  }, []);
  
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MZTSV929');
      `;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen">
          <Navigation />
          <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/paper/:paperId" element={<AuthStatusWrapper><PaperDetails /></AuthStatusWrapper>} />
              <Route path="/redirect" element={<RedirectHandler />} />
              <Route path="/paper-rankings" element={<BattleVisualizations />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;