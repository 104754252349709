const isDevelopment = process.env.NODE_ENV === 'development';

const getEnvVariable = (key) => {
  if (isDevelopment) {
    return process.env[key];
  }
  if (window._env_ && window._env_[key]) {
    return window._env_[key];
  }
  return undefined;
};

const config = {
  REACT_APP_API_URL: 'https://api.everscope.ai',
  // Add other variables as needed, e.g.:
  // REACT_APP_FEATURE_FLAG: getEnvVariable('REACT_APP_FEATURE_FLAG'),
};

// For debugging
console.log(`Environment: ${isDevelopment ? 'Development' : 'Production'}`);
console.log('Config:', config);

export default config;