import React, { useState } from 'react';

const About = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 max-w-7xl">
      <div className="flex flex-col md:flex-row mb-12">
        <div className="md:w-1/2 pr-4 mb-6 md:mb-0 flex flex-col justify-center">
          <h1 className="text-4xl font-light text-gray-900 mb-6">
            About Quant Explorer
          </h1>
          <p className="text-lg text-gray-700">
            <a href="/" className="text-blue-700">Everscope</a> by Cadenzai is a tool designed to discover and surface high quality
            research papers in computational finance. It uses a combination of machine learning techniques to compare
            and rank papers based on various criteria.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center items-center">
          <div className="w-full max-w-md aspect-square relative">
            <div className={`absolute inset-0 bg-gray-200 rounded-lg ${imageLoaded ? 'hidden' : 'flex items-center justify-center'}`}>
              <svg className="animate-spin h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
            <img
              src="/large_circuit_mandala.jpeg"
              alt="Circuit Mandala"
              className={`w-full h-full object-cover rounded-lg shadow-lg transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
              onLoad={() => setImageLoaded(true)}
            />
          </div>
        </div>
      </div>
      <div className="space-y-8">
              {/*

        <h2 className="text-3xl font-thin text-gray-900 mt-8 mb-4">
          Key Components
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h3 className="text-2xl font-medium text-gray-900">Paper Battles</h3>
            <p className="text-lg text-gray-700">
              The core of Quant Explorer is the "Paper Battle" system:
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Newly published papers are compared in head-to-head "battles".</li>
              <li>These battles are evaluated by Large Language Models (LLMs).</li>
              <li><a href="/paper-rankings" className="text-indigo-600 hover:text-indigo-800">Over 30,000 paper battles</a> have been conducted to date. <a href="/paper-rankings" className="text-indigo-600 hover:text-indigo-800">See more here.</a></li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="text-2xl font-medium text-gray-900">Battle-Algo</h3>
            <p className="text-lg text-gray-700">
              "battle-algo" is the system responsible for matching and battling papers:
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>It extracts features from paper metadata to create a feature matrix.</li>
              <li>Papers are matched for battles using a k-nearest neighbors (KNN) algorithm.</li>
              <li>The system is horizontally scalable, using Kubernetes to process thousands of battles concurrently.</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="text-2xl font-medium text-gray-900">Evaluation Criteria</h3>
            <p className="text-lg text-gray-700">
              LLMs assess papers based on several factors, including:
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Methodology and technical innovation</li>
              <li>Performance and results</li>
              <li>Practical applicability</li>
              <li>Robustness and limitations</li>
              <li>Contribution to the field</li>
              <li>Long-term impact potential</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="text-2xl font-medium text-gray-900">Ranking System</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Papers receive an Elo score based on their performance in battles.</li>
              <li>Scores are updated after each battle.</li>
              <li>Battle results are stored for potential review or reanalysis.</li>
            </ul>
          </div>

          <div className="space-y-4 md:col-span-2">
            <h3 className="text-2xl font-medium text-gray-900">Metric and Summary Extraction</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>LLMs extract relevant metrics and key summaries from papers.</li>
              <li>There is a focus on identifying best-performing metrics in each paper.</li>
            </ul>
          </div>
        </div>
        */}
        <div className="space-y-4">
          <h2 className="text-2xl font-thin text-gray-900 mt-8 mb-4">
            Current Status and Ongoing Work
          </h2>
          <p className="text-gray-700">
            Work is ongoing to refine the system to reduce bias and promote higher quality papers.
          </p>

          <p className="text-gray-700">
            The Quant Explorer is a tool intended to complement traditional research methods. 
            Users should consider its evaluations as supplementary to careful reading and expert 
            assessment of papers in computational finance.
          </p>

          <p className="text-gray-700">
            Feedback from the computational finance community is welcome as we continue to develop this tool.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;